import { type PropsWithChildren } from 'react';
import Card from '@/components/Card';
import { classes } from './utils';

export interface OverviewCardProps extends PropsWithChildren {
  title: string;
  subtitle?: string | null;
  onViewAll?: () => void;
}

export default function OverviewCard({
  title,
  subtitle,
  onViewAll,
  children,
}: OverviewCardProps) {
  return (
    <Card className={classes.card}>
      <div className={classes.titleWrapper}>
        <div>
          <h1 className={classes.title}>{title}</h1>
          {subtitle ? <h4 className={classes.subtitle}>{subtitle}</h4> : null}
        </div>
        {onViewAll && (
          <button className={classes.button} onClick={onViewAll}>
            View all
          </button>
        )}
      </div>
      <div className={classes.body}>{children}</div>
    </Card>
  );
}
