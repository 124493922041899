import type { CSSProperties } from 'react';
import { twMerge } from 'tailwind-merge';
import { classes, PROJECT_STATUS_CLASSES, styles } from './utils';

export type ProjectStatusCircleProps = {
  className?: string;
  style?: CSSProperties;
  size?: number;
  projectStatusID: keyof typeof PROJECT_STATUS_CLASSES;
};

export default function ProjectStatusCircle({
  className = '',
  style = {},
  size = 16,
  projectStatusID,
}: ProjectStatusCircleProps) {
  return (
    <div
      className={twMerge(
        classes.wrapper,
        PROJECT_STATUS_CLASSES[projectStatusID],
        className
      )}
      style={{ ...styles.wrapper(size), ...style }}
    />
  );
}
