import List from '@mui/material/List';
import { type DecoratedUnisonProject } from '@witmetrics/api-client';
import ProjectsListItem from './ProjectsListItem';

export type ProjectsListProps = {
  className?: string;
  size?: 'sm';
  isFetchingData: boolean;
  projects: DecoratedUnisonProject[];
};

export default function ProjectsList({
  className,
  size,
  isFetchingData,
  projects,
}: ProjectsListProps) {
  // TODO: Show skeletons when isFetchingData
  return (
    <List className={className}>
      {projects.map((project) => (
        <ProjectsListItem key={project.id} size={size} project={project} />
      ))}
    </List>
  );
}
