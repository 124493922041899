import { API } from '@/api';

export const classes = {
  list: 'max-h-[400px] overflow-auto',
};

export function fetchConversations() {
  return API.Conversations.fetchConversations({
    pagination: { page: 1, pageSize: 20 },
    sorting: { sortBy: 'updatedAt', sortOrder: 'DESCENDING' },
    filters: [],
  });
}
