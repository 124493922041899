import { API } from '@/api';
import { COMPARISON_OPERATORS } from '@/constants/comparisonOperators';
import { buildColumnFilter } from '@/utils/filters';

const { INCLUDES, EQUALS } = COMPARISON_OPERATORS;

export const classes = {
  list: 'py-0 max-h-[50vh]',
};

export async function fetchTasks(practiceID: number, userID: number) {
  return API.Practices.fetchPracticeUnisonProjectTasks(practiceID, {
    pagination: { page: 1, pageSize: 25 },
    sorting: { sortBy: 'dueAt', sortOrder: 'ASCENDING' },
    filters: [
      buildColumnFilter('isResolved', INCLUDES.value, [false]),
      buildColumnFilter('assignedTo', EQUALS.value, userID),
    ],
  });
}
