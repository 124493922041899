import { type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import { formatNumber } from '@/utils/strings';
import { classes, styles } from './utils';

export type ReportBlockProps = {
  isFetchingData: boolean;
  label: string;
  amount: number;
  labelSize?: 'sm';
  difference?: number;
  sign?: number;
  iconClasses: string;
  icon: ReactNode;
};

export default function ReportBlock({
  isFetchingData,
  label,
  amount,
  labelSize,
  difference,
  sign,
  iconClasses,
  icon,
}: ReportBlockProps) {
  return (
    <Grid item xs={6} md={6} lg={3}>
      <div className={classes.reportWrapper}>
        <div className={twMerge(classes.iconWrapper, iconClasses)}>{icon}</div>
        <div className={classes.labelWrapper}>
          <div
            className={twMerge(
              classes.label,
              labelSize === 'sm' ? classes.labelSmall : classes.labelBase
            )}>
            {label}
          </div>
          <div className={classes.amount}>
            {isFetchingData ? (
              <Skeleton
                width={60}
                height={labelSize === 'sm' ? 30 : 40}
                sx={styles.skeleton}
              />
            ) : (
              formatNumber(amount)
            )}
          </div>
        </div>
      </div>
    </Grid>
  );
}
