import type { DecoratedUnisonProject } from '@witmetrics/api-client';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Tooltip from '@/components/Tooltip';
import TruncatedText from '@/components/TruncatedText';
import { useDateFormatPreference } from '@/store/useStore';
import { useAppState } from '@/providers/AppStateProvider';
import ProjectStatusCircle from '@/components/ProjectStatusCircle';
import {
  classes,
  getDescriptionString,
  getUpdatedAtString,
  styles,
} from './utils';

export type ProjectsListItemProps = {
  size?: 'sm';
  project: DecoratedUnisonProject;
};

export default function ProjectsListItem({
  size,
  project,
}: ProjectsListItemProps) {
  const dateFormat = useDateFormatPreference();
  const { onSelectProject } = useAppState();

  const isSmall = size === 'sm';

  return (
    <ListItem disablePadding>
      <ListItemButton
        sx={styles.listItemButton}
        className={classes.listItemButton}
        onClick={() => onSelectProject(project.id)}>
        <div className={classes.infoBar}>
          <div className={classes.nameWrapper}>
            <div className={classes.statusWrapper}>
              <Tooltip title={project.unisonProjectStatus.label}>
                <ProjectStatusCircle
                  projectStatusID={project.unisonProjectStatusID}
                />
              </Tooltip>
            </div>
            <TruncatedText
              className={classes.name}
              textClassName={isSmall ? classes.nameTextSm : classes.nameText}>
              {project.name}
            </TruncatedText>
          </div>
          <div className={isSmall ? classes.dateSm : classes.date}>
            {getUpdatedAtString(project.updatedAt, dateFormat)}
          </div>
        </div>
        <div className={isSmall ? classes.descriptionSm : classes.description}>
          {getDescriptionString(project.description).label}
        </div>
      </ListItemButton>
    </ListItem>
  );
}
