import type { DecoratedUnisonProjectTask } from '@witmetrics/api-client';
import { twMerge } from 'tailwind-merge';
import Options from './Options';
import { renderUserName } from '@/utils/strings';
import ProfilePicture from '@/components/ProfilePicture';
import Tooltip from '@/components/Tooltip';
import CheckBox from '@/components/CheckBox';
import { useAppState } from '@/providers/AppStateProvider';
import { classes } from './utils';

export type UnisonProjectTaskHeaderProps = {
  isUpdating: boolean;
  unisonProjectTask: DecoratedUnisonProjectTask;
  onToggle: (isResolved: boolean) => void;
  onEdit: () => void;
  onDelete: () => void;
};

export default function UnisonProjectTaskHeader({
  isUpdating,
  unisonProjectTask: { task, unisonProject },
  onToggle,
  onEdit,
  onDelete,
}: UnisonProjectTaskHeaderProps) {
  const { onSelectProject } = useAppState();

  const resolvedClasses = task.isResolved ? classes.resolved : '';

  const handleToggle = ({ isResolved }: { isResolved: boolean }) => {
    return onToggle(isResolved);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.leftContent}>
        <div className={classes.checkboxWrapper}>
          <CheckBox
            name="isResolved"
            isUpdating={isUpdating}
            isChecked={task.isResolved}
            onChange={handleToggle}
          />
        </div>
        <button
          className={twMerge(classes.userName, resolvedClasses)}
          onClick={() => onSelectProject(unisonProject.id)}>
          {unisonProject.name}
        </button>
      </div>
      <div className={classes.rightContent}>
        <Tooltip title={renderUserName(task.assignedTo)}>
          <div>
            <ProfilePicture
              size={25}
              className={classes.profilePicture}
              user={task.assignedTo}
            />
          </div>
        </Tooltip>
        <Options onEdit={onEdit} onDelete={onDelete} />
      </div>
    </div>
  );
}
