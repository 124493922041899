import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Tooltip from '@/components/Tooltip';
import { useToggle } from '@/hooks/useToggle';
import { classes, getLabel } from './utils';

export type TruncatedTextProps = {
  className?: string;
  textClassName?: string;
  maxLength?: number;
  children: string;
};

export default function TruncatedText({
  className = '',
  textClassName = '',
  maxLength,
  children,
}: TruncatedTextProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [label, setLabel] = useState('');
  const [isTruncated, toggleTruncated] = useToggle(false);

  useEffect(() => {
    const labelSettings = getLabel(children, maxLength);
    setLabel(labelSettings.label);
    toggleTruncated(labelSettings.isTruncated);
  }, [maxLength, children]);

  useLayoutEffect(() => {
    if (maxLength === undefined) checkTruncate();
  }, [label]);

  const checkTruncate = () => {
    if (!contentRef.current) return;
    const { offsetWidth, scrollWidth } = contentRef.current;
    if (offsetWidth < scrollWidth) {
      setLabel(children);
      toggleTruncated(true);
    }
  };

  const content = (
    <div
      ref={contentRef}
      className={twMerge(
        textClassName,
        maxLength === undefined && classes.clipText
      )}>
      {label}
    </div>
  );

  return (
    <div className={twMerge(className, classes.wrapper)}>
      {isTruncated ? <Tooltip title={children}>{content}</Tooltip> : content}
    </div>
  );
}
