import { useEffect, useState } from 'react';
import {
  useActiveAccount,
  useCurrentUser,
  useDispatch,
  useUnisonProjectTasksFromIDArray,
} from '@/store/useStore';
import {
  buildUnisonProjectTaskKey,
  setUnisonProjectTasks as dispatchSetUnisonProjectTasks,
} from '@/store/slices/unisonProjectTasksSlice';
import EmptyMessage from '../EmptyMessage';
import OverviewCard from '../OverviewCard';
import UnisonProjectTasksList from '@/components/UnisonProjectTasksList';
import { useToggle } from '@/hooks/useToggle';
import { usePath } from '@/hooks/usePath';
import { useAppState } from '@/providers/AppStateProvider';
import { classes, fetchTasks } from './utils';

export default function TasksCard() {
  const dispatch = useDispatch();
  const { navigate } = usePath();
  const { onApiError } = useAppState();
  const currentUser = useCurrentUser();
  const activeAccount = useActiveAccount();
  const [taskKeys, setTaskKeys] = useState<string[]>([]);
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const tasks = useUnisonProjectTasksFromIDArray(taskKeys);

  useEffect(() => {
    if (activeAccount && currentUser) fetchData();
  }, [currentUser, activeAccount]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const data = await fetchTasks(activeAccount!.accountID, currentUser!.id);
      dispatch(dispatchSetUnisonProjectTasks(data.results));
      setTaskKeys(
        data.results.map((upt) =>
          buildUnisonProjectTaskKey(upt.unisonProjectID, upt.taskID)
        )
      );
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching tasks', () => fetchData());
    }
  };

  return (
    <OverviewCard
      title="My Tasks"
      subtitle={tasks !== null ? `${tasks.length} total` : null}
      onViewAll={() => navigate('/dashboard/tasks')}>
      {isFetchingData || tasks.length > 0 ? (
        <UnisonProjectTasksList
          className={classes.list}
          isFetchingData={isFetchingData}
          unisonProjectTasks={tasks}
        />
      ) : (
        <EmptyMessage>No open tasks.</EmptyMessage>
      )}
    </OverviewCard>
  );
}
