import { API } from '@/api';

export const classes = {
  wrapper: 'flex justify-between max-w-[800px] px-6 py-8',
  gridContainer: 'max-w-[800px]',
  peopleIcon: 'bg-purple-2',
  bookingsIcon: 'bg-pink-2',
  treatmentsIcon: 'bg-cyan-2',
  revenueIcon: 'bg-green-2',
};

export async function fetchGlances(practiceID: number) {
  const report = await API.Reports.fetchUnisonProjectGlancesReport(practiceID);
  let result = { active: 0, done: 0 };
  Object.keys(report[0].value).forEach((key) => {
    if (key === 'DONE') {
      result.done = report[0].value[key];
    } else {
      result.active += report[0].value[key];
    }
  });
  return result;
}
