import type { DecoratedUnisonProjectTask } from '@witmetrics/api-client';
import Skeleton from '@mui/material/Skeleton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import UnisonProjectTaskListItem from './UnisonProjectTaskListItem';
import { classes, styles } from './utils';

export type UnisonProjectTasksListProps = {
  className?: string;
  isFetchingData: boolean;
  unisonProjectTasks: DecoratedUnisonProjectTask[];
};

export default function UnisonProjectTasksList({
  className = '',
  isFetchingData,
  unisonProjectTasks = [],
}: UnisonProjectTasksListProps) {
  const sortedTasks = unisonProjectTasks.sort((a, b) => {
    if (b.task.dueAt > a.task.dueAt) return -1;
    if (a.task.dueAt > b.task.dueAt) return 1;
    return 0;
  });

  return (
    <List className={className}>
      {isFetchingData
        ? [0, 0.2, 0.3].map((delay) => (
            <ListItem key={delay} sx={styles.listItem}>
              <Skeleton
                className={classes.skeleton}
                variant="rectangular"
                animation="wave"
                sx={styles.skeleton(delay)}
              />
            </ListItem>
          ))
        : sortedTasks.map((upt) => (
            <UnisonProjectTaskListItem
              key={upt.task.id}
              unisonProjectTask={upt}
            />
          ))}
    </List>
  );
}
