import { useEffect, useState } from 'react';
import {
  useActiveAccount,
  useDateFormatPreference,
  useDispatch,
  useUnisonProjectsFromIDArray,
} from '@/store/useStore';
import EmptyMessage from '../EmptyMessage';
import OverviewCard from '../OverviewCard';
import ProjectsList from '@/components/ProjectsList';
import { setUnisonProjects as dispatchSetUnisonProjects } from '@/store/slices/unisonProjectsSlice';
import { useToggle } from '@/hooks/useToggle';
import { useAppState } from '@/providers/AppStateProvider';
import { usePath } from '@/hooks/usePath';
import { classes, fetchActiveProjects, STATUS_FILTER } from './utils';

export default function ProjectsCard() {
  const dispatch = useDispatch();
  const { navigate } = usePath();
  const { onApiError } = useAppState();
  const activeAccount = useActiveAccount();
  const dateFormat = useDateFormatPreference();
  const [projectIDs, setProjectIDs] = useState<number[]>([]);
  const [totalProjects, setTotalProjects] = useState<number | null>(null);
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const projects = useUnisonProjectsFromIDArray(projectIDs);

  useEffect(() => {
    if (activeAccount) {
      setProjectIDs([]);
      setTotalProjects(null);
      fetchData();
    }
  }, [activeAccount]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const data = await fetchActiveProjects();
      dispatch(dispatchSetUnisonProjects(data.results));
      setProjectIDs(data.results.map(({ id }) => id));
      setTotalProjects(data.pagination.rowCount);
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching projects', () => fetchData());
    }
  };

  const handleViewAll = () => {
    navigate('/dashboard/projects/', {
      filters: JSON.stringify([STATUS_FILTER]),
    });
  };

  return (
    <OverviewCard
      title="Active Projects"
      subtitle={totalProjects !== null ? `${totalProjects} total` : null}
      onViewAll={handleViewAll}>
      {isFetchingData || projects.length > 0 ? (
        <ProjectsList
          isFetchingData={isFetchingData}
          size="sm"
          className={classes.list}
          projects={projects}
        />
      ) : (
        <EmptyMessage>No active projects.</EmptyMessage>
      )}
    </OverviewCard>
  );
}
