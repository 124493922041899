import { useEffect, useState } from 'react';
import CasesIcon from '@/icons/CasesIcon';
import CheckBoxIcon from '@/icons/CheckBoxIcon';
import SyncIcon from '@/icons/SyncIcon';
import Grid from '@mui/material/Grid';
import OverviewCard from '../OverviewCard';
import { useToggle } from '@/hooks/useToggle';
import ReportBlock from '@/components/ReportBlock';
import { useActiveAccount } from '@/store/useStore';
import { useAppState } from '@/providers/AppStateProvider';
import { classes, fetchGlances } from './utils';

export default function GlancesCard() {
  const { onApiError } = useAppState();
  const activeAccount = useActiveAccount();
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const [report, setReport] = useState({ active: 0, done: 0 });

  useEffect(() => {
    if (activeAccount) {
      setReport({ active: 0, done: 0 });
      fetchData();
    }
  }, [activeAccount]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const data = await fetchGlances(activeAccount!.accountID);
      setReport(data);
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching reports', () => fetchData());
    }
  };

  return (
    <OverviewCard title="Glances" subtitle="Created in past 30 days">
      <div className={classes.wrapper}>
        <Grid
          container
          className={classes.gridContainer}
          rowSpacing={3}
          columnSpacing={3}>
          <Grid item xs={6} md={6} lg={3}>
            <ReportBlock
              isFetchingData={isFetchingData}
              label="Projects"
              amount={report.active + report.done}
              iconClasses={classes.peopleIcon}
              // @ts-ignore
              icon={<CasesIcon color="white" fontSize="medium" />}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={3}>
            <ReportBlock
              isFetchingData={isFetchingData}
              label="Active"
              amount={report.active}
              iconClasses={classes.treatmentsIcon}
              // @ts-ignore
              icon={<SyncIcon color="white" fontSize="medium" />}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={3}>
            <ReportBlock
              isFetchingData={isFetchingData}
              label="Done"
              amount={report.done}
              iconClasses={classes.revenueIcon}
              // @ts-ignore
              icon={<CheckBoxIcon color="white" fontSize="medium" />}
            />
          </Grid>
        </Grid>
      </div>
    </OverviewCard>
  );
}
