import { theme } from '@/utils/theme';

export const classes = {
  content: 'grow flex flex-col py-2',
  header: 'flex w-full justify-between mb-2',
  leadName: 'font-medium text-sm mr-2 hover:underline cursor-pointer',
  assignedTo: 'text-sm',
  description: 'font-normal text-sm px-2',
  resolved: 'text-grey-5 line-through',
  nameWrapper: 'pl-2 flex flex-col',
  closedName: 'text-grey-6',
  openName: 'text-grey-8',
  closedAssignedTo: 'text-grey-5',
  openAssignedTo: 'text-grey-7',
  optionsWrapper: 'flex justify-center items-start ml-3',
  optionsIcon: 'cursor-pointer',
  closedDescription: 'text-grey-5',
  openDescription: 'text-grey-7',
};

export const styles = {
  listItem: {
    '&': {
      alignItems: 'flex-start',
    },
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette['grey-3'].main}`,
    },
  },
};
