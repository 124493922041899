import { API } from '@/api';
import { COMPARISON_OPERATORS } from '@/constants/comparisonOperators';
import { PROJECT_STATUSES } from '@/constants/projectStatuses';
import { buildColumnFilter } from '@/utils/filters';

const activeStatuses = Object.values(PROJECT_STATUSES)
  .filter((status) => status.name !== 'DONE')
  .map((status) => status.id);

export const STATUS_FILTER = buildColumnFilter(
  'unisonProjectStatusID',
  COMPARISON_OPERATORS.INCLUDES.value,
  activeStatuses
);

export const classes = {
  list: 'max-h-[400px] overflow-auto',
};

export async function fetchActiveProjects() {
  return API.UnisonProjects.fetchUnisonProjects({
    pagination: { page: 1, pageSize: 10 },
    sorting: { sortBy: 'updatedAt', sortOrder: 'DESCENDING' },
    filters: [STATUS_FILTER],
  });
}
