import { truncateString } from '@/utils/strings';

export const classes = {
  wrapper: 'min-w-0',
  clipText: 'truncate',
};

export function getLabel(str: string, maxLength?: number) {
  if (maxLength === undefined) return { label: str, isTruncated: false };
  const label = truncateString(str, maxLength);
  return { label, isTruncated: str.length > label.length };
}
