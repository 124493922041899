import type { DecoratedTask } from '@witmetrics/api-client';
import { classes } from './utils';

export type TaskNotesProps = {
  task: DecoratedTask;
};

export default function TaskNotes({ task }: TaskNotesProps) {
  return <p className={classes.wrapper}>{task.description}</p>;
}
