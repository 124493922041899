'use client';
import Grid from '@mui/material/Grid';
import GlancesCard from './GlancesCard';
import ProjectsCard from './ProjectsCard';
import ConversationsCard from './ConversationsCard';
import TasksCard from './TasksCard';
import { classes, COLUMN_SPACING, ROW_SPACING } from './utils';

export default function Page() {
  return (
    <div className={classes.wrapper}>
      <Grid container rowSpacing={ROW_SPACING} columnSpacing={COLUMN_SPACING}>
        <Grid item xs={12} md={8}>
          <Grid
            container
            rowSpacing={ROW_SPACING}
            columnSpacing={COLUMN_SPACING}>
            <Grid item xs={12}>
              <GlancesCard />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                rowSpacing={ROW_SPACING}
                columnSpacing={COLUMN_SPACING}>
                <Grid item xs={12} md={6}>
                  <ProjectsCard />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ConversationsCard />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <TasksCard />
        </Grid>
      </Grid>
    </div>
  );
}
