import type { DecoratedTask } from '@witmetrics/api-client';
import { twMerge } from 'tailwind-merge';
import { isPast, isToday, isTomorrow, renderDateWithTime } from '@/utils/time';
import TimeIcon from '@/icons/TimeIcon';
import { classes } from './utils';

export type DueDateProps = {
  task: DecoratedTask;
  dateFormat?: string;
};

export default function DueDate({ task, dateFormat }: DueDateProps) {
  let dateClasses = '';
  let time = renderDateWithTime(task.dueAt, dateFormat);
  if (task.isResolved) {
    dateClasses = classes.resolved;
  } else if (isToday(task.dueAt)) {
    time = 'Due today';
    dateClasses = classes.today;
  } else if (isTomorrow(task.dueAt)) {
    time = 'Due tomorrow';
    dateClasses = classes.tomorrow;
  } else if (isPast(task.dueAt)) {
    dateClasses = classes.past;
  } else {
    dateClasses = classes.future;
  }
  return (
    <div className={classes.wrapper}>
      <TimeIcon className={classes.icon} />
      <div className={twMerge(classes.dueAt, dateClasses)}>{time}</div>
    </div>
  );
}
