import { useEffect, useState } from 'react';
import {
  useActiveAccount,
  useConversationsFromIDArray,
  useDispatch,
} from '@/store/useStore';
import { setConversations as dispatchSetConversations } from '@/store/slices/conversationsSlice';
import EmptyMessage from '../EmptyMessage';
import OverviewCard from '../OverviewCard';
import ConversationsList from '@/components/ConversationsList';
import { useToggle } from '@/hooks/useToggle';
import { usePath } from '@/hooks/usePath';
import { useAppState } from '@/providers/AppStateProvider';
import { mapToID } from '@/utils/arrays';
import { showErrorToaster } from '@/utils/toasters';
import { classes, fetchConversations } from './utils';

export default function ConversationsCard() {
  const dispatch = useDispatch();
  const { onApiError, onSelectProject } = useAppState();
  const { navigate } = usePath();
  const activeAccount = useActiveAccount();
  const [conversationIDs, setConversationIDs] = useState<number[]>([]);
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const conversations = useConversationsFromIDArray(conversationIDs);

  useEffect(() => {
    setConversationIDs([]);
    fetchData();
  }, [activeAccount]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const data = await fetchConversations();
      dispatch(dispatchSetConversations(data.results));
      setConversationIDs(mapToID(data.results));
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching conversations', () => fetchData());
    }
  };

  const handleSelectConversation = (id: number) => {
    const conversation = conversations!.find((c) => c.id === id)!;
    if (conversation.unisonProjects.length === 0) {
      showErrorToaster('Error opening conversation');
      throw Error(`No project found for conversation (id: ${id})`);
    }
    onSelectProject(conversation.unisonProjects[0].unisonProjectID, {
      activeConversationID: id,
    });
  };

  return (
    <OverviewCard
      title="Conversations"
      subtitle="&nbsp;"
      onViewAll={() => navigate('/dashboard/conversations/')}>
      {isFetchingData || conversations.length > 0 ? (
        <ConversationsList
          showProject
          isFetchingData={isFetchingData}
          className={classes.list}
          size="sm"
          conversations={conversations}
          onSelect={handleSelectConversation}
        />
      ) : (
        <EmptyMessage>No conversations.</EmptyMessage>
      )}
    </OverviewCard>
  );
}
