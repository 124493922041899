import type { DecoratedUnisonProjectTask } from '@witmetrics/api-client';
import { useDateFormatPreference, useDispatch } from '@/store/useStore';
import { updateTask as dispatchUpdateTask } from '@/store/slices/tasksSlice';
import { API } from '@/api';
import ListItem from '@mui/material/ListItem';
import DeleteTaskDialog from '@/dialogs/DeleteTaskDialog';
import UnisonProjectTaskDialog from '@/dialogs/UnisonProjectTaskDialog';
import TaskNotes from './TaskNotes';
import UnisonProjectTaskHeader from './UnisonProjectTaskHeader';
import DueDate from './DueDate';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { classes, styles } from './utils';

export type UnisonProjectTaskListItemProps = {
  unisonProjectTask: DecoratedUnisonProjectTask;
};

export default function UnisonProjectTaskListItem({
  unisonProjectTask,
}: UnisonProjectTaskListItemProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const dateFormat = useDateFormatPreference();
  const [isEditDialogShown, toggleEditDialog] = useToggle(false);
  const [isDeleteWarningShown, toggleDeleteWarning] = useToggle(false);
  const [isUpdating, toggleUpdating] = useToggle(false);

  const { task, unisonProject } = unisonProjectTask;

  const handleEditTask = () => {
    toggleEditDialog(true);
  };

  const handleCancelEdit = () => {
    toggleEditDialog(false);
  };

  const handleShowDeleteWarning = () => {
    toggleDeleteWarning();
  };

  const handleToggleChecked = async (isResolved: boolean) => {
    try {
      toggleUpdating(true);
      const updatedTask = await API.Tasks.updateTask(task.id, { isResolved });
      dispatch(dispatchUpdateTask(updatedTask));
      toggleUpdating(false);
    } catch (err) {
      toggleUpdating(false);
      onApiError(err, 'Error saving task');
    }
  };

  return (
    <>
      {isEditDialogShown && (
        <UnisonProjectTaskDialog
          taskID={task.id}
          projectID={unisonProject.id}
          onClose={handleCancelEdit}
        />
      )}
      {isDeleteWarningShown && (
        <DeleteTaskDialog
          taskID={task.id}
          onClose={() => toggleDeleteWarning()}
        />
      )}
      <ListItem key={task.id} sx={styles.listItem}>
        <div className={classes.content}>
          <UnisonProjectTaskHeader
            isUpdating={isUpdating}
            unisonProjectTask={unisonProjectTask}
            onToggle={(isResolved) => handleToggleChecked(isResolved)}
            onEdit={handleEditTask}
            onDelete={handleShowDeleteWarning}
          />
          <DueDate task={task} dateFormat={dateFormat} />
          <TaskNotes task={task} />
        </div>
      </ListItem>
    </>
  );
}
