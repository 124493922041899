import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import TruncatedText from '@/components/TruncatedText';
import type { DecoratedConversationWithProject } from '@witmetrics/api-client';
import {
  useConversationUsers,
  useDateFormatPreference,
} from '@/store/useStore';
import { classes, getSubjectString, getUpdatedAtString, styles } from './utils';

export type ConversationsListItemProps = {
  showProject?: boolean;
  size?: 'sm';
  conversation: DecoratedConversationWithProject;
  onSelect: (conversationID: number) => void;
};

export default function ConversationsListItem({
  showProject,
  size,
  conversation,
  onSelect,
}: ConversationsListItemProps) {
  const dateFormat = useDateFormatPreference();
  const members = useConversationUsers(conversation.id);

  const isSmall = size === 'sm';

  // TODO: Include a preview of the most recent message (e.g. "Sean: Hey, how's it going?")

  const renderProject = () => {
    const project = conversation.unisonProjects[0];
    return (
      <div className={isSmall ? classes.projectNameSm : classes.projectName}>
        {project?.unisonProject.name || ''}
      </div>
    );
  };

  return (
    <ListItem disablePadding>
      <ListItemButton
        sx={styles.listItemButton}
        className={classes.listItemButton}
        onClick={() => onSelect(conversation.id)}>
        <div className={classes.infoBar}>
          <TruncatedText
            textClassName={isSmall ? classes.subjectSm : classes.subject}>
            {getSubjectString(conversation.subject, members)}
          </TruncatedText>
          <div className={isSmall ? classes.dateSm : classes.date}>
            {getUpdatedAtString(conversation.updatedAt, dateFormat)}
          </div>
        </div>
        {showProject && renderProject()}
      </ListItemButton>
    </ListItem>
  );
}
