import { useRef } from 'react';
import DefaultOptionsMenu from '@/components/DefaultOptionsMenu';
import OptionsIcon from '@/icons/OptionsIcon';
import { useToggle } from '@/hooks/useToggle';
import { classes } from './utils';

export type OptionsProps = {
  onEdit: () => void;
  onDelete: () => void;
};

export default function Options({ onEdit, onDelete }: OptionsProps) {
  const menuToggleRef = useRef(null);
  const [isMenuOpen, toggleMenu] = useToggle(false);

  const handleEdit = () => {
    onEdit();
    toggleMenu();
  };

  const handleDelete = () => {
    onDelete();
    toggleMenu();
  };

  return (
    <div className={classes.wrapper}>
      <OptionsIcon
        ref={menuToggleRef}
        className={classes.icon}
        // @ts-ignore
        color="grey-6"
        onClick={() => toggleMenu()}
      />
      <DefaultOptionsMenu
        open={isMenuOpen}
        anchorTo={menuToggleRef.current}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onClose={() => toggleMenu()}
      />
    </div>
  );
}
