export const classes = {
  reportWrapper: 'flex h-12 px-1',
  iconWrapper:
    'h-12 w-12 rounded-lg shadow-card flex justify-center items-center shrink-0',
  labelWrapper: 'flex flex-col items-start justify-between h-full ml-4',
  label: 'text-grey-7 leading-none',
  labelSmall: 'text-xs',
  labelBase: 'text-base',
  amount: 'text-lg text-grey-7 font-semibold leading-normal',
};

export const styles = {
  skeleton: { fontSize: '1.85rem', lineHeight: '1.4' },
};
