import { theme } from '@/utils/theme';

export const classes = {
  skeleton: 'py-8 w-full',
};

export const styles = {
  listItem: {
    '&': { padding: 0 },
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette['grey-3'].main}`,
    },
  },
  skeleton: (delay: number) => ({
    '&:after': {
      animationDelay: `${delay}s`,
      animationDuration: '1.2s',
    },
  }),
};
