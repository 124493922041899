import { theme } from '@/utils/theme';
import {
  isToday,
  isYesterday,
  renderDateOnlyTime,
  renderTextDate,
} from '@/utils/time';
import { DecoratedConversationUser } from '@witmetrics/api-client';
import { isBlank, renderUserName } from '@/utils/strings';
import { sortAlphabetical } from '@/utils/arrays';

export const classes = {
  listItemButton:
    'transition-all duration-100 py-4 px-6 my-1 mx-4 rounded-lg flex flex-col items-start',
  infoBar:
    'flex justify-between items-center w-full text-base text-grey-8 font-medium',
  date: 'text-sm',
  subject: 'text-base font-medium text-grey-8',
  projectName: 'text-sm font-normal text-grey-7',
  dateSm: 'text-xs',
  subjectSm: 'text-sm font-medium text-grey-8',
  projectNameSm: 'text-sm font-normal text-grey-7',
};

export const styles = {
  listItemButton: {
    '&': {
      width: 'unset',
      backgroundColor: theme.palette['white'].main,
    },
    '&:hover': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '& .MuiTypography-body1': {
      fontSize: '1rem', // text-base
      lineHeight: '1.5rem', // text-base
      color: theme.palette['grey-8'].main,
    },
    '& .MuiTypography-body2': {
      fontSize: '0.875rem', // text-sm
      lineHeight: '1.25rem', // text-sm
      color: theme.palette['grey-7'].main,
    },
  },
};

export function getSubjectString(
  subject: string,
  members: DecoratedConversationUser[] | null
) {
  if (!isBlank(subject)) {
    return subject;
  } else if (members && members.length > 0) {
    return sortAlphabetical(
      members.map(({ user }) => renderUserName(user))
    ).join(', ');
  } else {
    return '[No subject]';
  }
}

export function getUpdatedAtString(
  updatedAt: string,
  dateFormat: string | undefined
) {
  if (isToday(updatedAt)) {
    return renderDateOnlyTime(updatedAt);
  } else if (isYesterday(updatedAt)) {
    return `Yesterday, ${renderDateOnlyTime(updatedAt)}`;
  } else if (dateFormat) {
    return renderTextDate(updatedAt, dateFormat);
  } else {
    return renderTextDate(updatedAt);
  }
}
